import { createReactBlockSpec } from '@blocknote/react'
import React from 'react'

export const CodeBlock = createReactBlockSpec(
  {
    type: 'code_block',
    propSchema: {
      language: { default: 'javascript' }
    },
    content: 'inline'
  },
  {
    render: (props) => {
      return (
        <div className='rounded-sm bg-[#faf6ea] px-8'>
          <pre>
            <code ref={props.contentRef} />
          </pre>
        </div>
      )
    }
  }
)
