import type { PartialBlock } from '@blocknote/core'
import { useCreateBlockNote } from '@blocknote/react'
import type { RootState } from '@shared/store'
import { Breadcrumb, Divider, message, Popover, Tooltip } from 'antd'
import { format, parseISO } from 'date-fns'
import jsPDF from 'jspdf'
import React, { useState } from 'react'
import { BsLink45Deg } from 'react-icons/bs'
import { IoCopyOutline, IoDownloadOutline, IoEllipsisHorizontal, IoTrashOutline } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import type { NavigateFunction } from 'react-router-dom'
import { useNavigate, useParams } from 'react-router-dom'

import type { NoteItem } from '../common/type'
import { deleteNote, duplicateNote } from '../reducers/tnoteReducer'
import { schema } from './Editor'

const Navbar: React.FC = () => {
  const dispatch = useDispatch<any>()
  const navigate: NavigateFunction = useNavigate()
  const { noteId } = useParams()
  const items = useSelector((state: RootState) => state.tnote.note.items)

  const note: NoteItem = items.find((item) => item.id === noteId)
  const [popupVisible, setPopupVisible] = useState(false)

  const formatTime = (time: string) => {
    const parsedDate = parseISO(time)
    const formattedDate = format(parsedDate, 'MMM d, yyyy HH:mm')

    return formattedDate
  }

  const editor = useCreateBlockNote({
    schema,
    initialContent: JSON.parse(note.content) as PartialBlock[]
  })

  const handleExport = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()
    try {
      const html = await editor.blocksToHTMLLossy(editor.document)
      const element = document.createElement('div')
      element.innerHTML = html

      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('p', 'pt', 'a4')
      pdf.html(element, {
        callback(instance) {
          instance.save(`${note?.title.replace(/ /g, '_')}.pdf`)
        },
        x: 15,
        y: 10
      })
    } catch (error) {}
  }

  const handleDelete = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()
    try {
      await dispatch(deleteNote(note?.id as string))
      message.success('Note deleted!')
      setTimeout(() => {
        const newItems = items.filter((item) => item.id !== note?.id)
        navigate(`/tnote/${newItems[0].id}`)
      }, 500)
    } catch (error) {}
  }

  const handleCopyLink = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()
    navigator.clipboard.writeText(`${window.location.origin}/tnote/${note?.id}`)
    setPopupVisible(false)
  }

  const hanldeDuplicate = async (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.stopPropagation()
    try {
      const rs = await dispatch(duplicateNote(note?.id as string)).unwrap()
      if (rs) {
        navigate(`/tnote/${rs.id}`)
      }
    } catch (error) {}
  }

  return (
    <div className='px-3'>
      <div className='flex min-h-[44px] items-center justify-between'>
        <Breadcrumb
          items={[
            {
              title: 'Tnote'
            },
            {
              title: (
                <Tooltip
                  placement='bottom'
                  overlayInnerStyle={{
                    minWidth: 'max-content'
                  }}
                  arrow={false}
                  title={note?.title}
                >
                  <span>{note?.title?.length > 32 ? `${note?.title.slice(0, 32).trim()}...` : note?.title}</span>
                </Tooltip>
              )
            }
          ]}
        />
        <div className='flex items-center gap-2'>
          {note?.updatedAt && <span className='text-black/50'>Edited at {formatTime(note?.updatedAt)}</span>}
          <Popover
            placement='bottomRight'
            style={{ borderRadius: '20px' }}
            arrow={false}
            open={popupVisible}
            onOpenChange={(visible) => setPopupVisible(visible)}
            content={
              <div className='-m-3 py-2'>
                <div onClick={handleExport} className='mx-1 flex h-7 cursor-pointer items-center rounded-md px-2 hover:bg-gray-100'>
                  <IoDownloadOutline className='mr-2 h-4 w-4' />
                  Export to PDF
                </div>

                <div onClick={handleCopyLink} className='mx-1 flex h-7 cursor-pointer items-center rounded-md px-2 hover:bg-gray-100'>
                  <BsLink45Deg className='mr-2 h-4 w-4' />
                  Copy Link
                </div>

                <div onClick={hanldeDuplicate} className='mx-1 flex h-7 cursor-pointer items-center rounded-md px-2 hover:bg-gray-100'>
                  <IoCopyOutline className='mr-2 h-4 w-4' />
                  Duplicate
                </div>

                <div onClick={handleDelete} className='mx-1 flex h-7 cursor-pointer items-center rounded-md px-2 hover:bg-gray-100 hover:text-red-400'>
                  <IoTrashOutline className='mr-2 h-4 w-4' />
                  Delete
                </div>
                <Divider className='my-1' />
                <div className='mx-3 flex flex-col gap-y-1 pt-1 text-xs text-gray-500'>
                  <span className='block'>{note?.updatedAt ? formatTime(note.updatedAt) : ''}</span>
                </div>
              </div>
            }
            trigger='click'
          >
            <div role='button' onClick={(e) => e.stopPropagation()} className='ml-auto h-7 w-8 rounded-sm center hover:bg-[#37352f0f]'>
              <IoEllipsisHorizontal size={22} />
            </div>
          </Popover>
        </div>
      </div>
    </div>
  )
}

export default Navbar
